import { compare } from "fast-json-patch";
import { Group, GroupListResponse, GroupDocumentResponse } from "../../types/group";
import { PeopleDocumentResponse, PeopleListResponse } from "../../types/people";
import { PermissionListResponse, PermissionDocumentResponse } from "../../types/permission";
import { QueryOptions } from "../../types";
import { urlPathWithQueryParams } from "../../utils/utils";
import { commonDataApi } from ".";

/**
 * Get list of groups
 * @return {Promise<GroupListResponse>}
 */
const getGroups = async (
  query: Partial<Group> = {},
  queryOptions: QueryOptions = {}
): Promise<GroupListResponse> => {
  return await commonDataApi.get(urlPathWithQueryParams("groups", { ...query, ...queryOptions }));
};

/**
 * Get list of groups by locationId
 * @param {string} applicationId
 * @param {string} locationId
 * @return {Promise<GroupListResponse>}
 */
const getByLocationId = async (
  applicationId: string,
  locationId: string,
  queryOptions: QueryOptions = {}
): Promise<GroupListResponse> => {
  return await commonDataApi.get(
    urlPathWithQueryParams(
      `applications/${applicationId}/locations/${locationId}/groups`,
      queryOptions
    )
  );
};

/**
 * Get list of groups by locationId
 * @param {string} applicationId
 * @param {string} siteId
 * @param {string} locationId
 * @param {string} personId
 * @return {Promise<GroupListResponse>}
 */
const getByUserId = async (
  applicationId: string,
  siteId: string,
  locationId: string,
  personId: string
): Promise<GroupListResponse> => {
  return await commonDataApi.get(
    `applications/${applicationId}/sites/${siteId}/locations/${locationId}/people/${personId}/groups`
  );
};

/**
 * Create a new Group
 * @param {Group} group
 * @return {Promise<GroupDocumentResponse>}
 */
const createGroup = async (group: Group): Promise<GroupDocumentResponse> => {
  return await commonDataApi.post("groups", group);
};

/**
 * Get Group Details
 * @param {groupId} string
 * @return {Promise<GroupDocumentResponse>}
 */
const getGroup = async (groupId: string): Promise<GroupDocumentResponse> => {
  return await commonDataApi.get(`groups/${groupId}`);
};

/**
 * Update existing Group
 * @param {string} groupId
 * @param {Group} group
 * @return {Promise<GroupDocumentResponse>}
 */
const updateGroup = async (
  groupId: string,
  updatedGroup: Group | Partial<Group>,
  group: Group
): Promise<GroupDocumentResponse> => {
  const patchUpdates = compare(group, updatedGroup);
  return await commonDataApi.patch(`groups/${groupId}`, patchUpdates);
};

/**
 * Delete a Group
 * @param {string} groupId
 * @return {Promise<void>}
 */
const deleteGroup = async (groupId: string): Promise<void> => {
  return await commonDataApi.delete(`groups/${groupId}`);
};

/**
 * Get Group Members
 * @param groupId {string} - Id of group
 * @returns {PeopleListResponse}
 */
const getGroupMembers = async (
  groupId: string,
  queryOptions: QueryOptions = {}
): Promise<PeopleListResponse> => {
  return await commonDataApi.get(
    urlPathWithQueryParams(`groups/${groupId}/members`, { ...queryOptions })
  );
};

/**
 * Get Group Permissions
 * @param {string} groupId
 * @return {Promise<PermissionListResponse>}
 */
const getGroupPermissions = async (groupId: string): Promise<PermissionListResponse> => {
  const url = urlPathWithQueryParams(`groups/${groupId}/permissions`, { size: 1000 });
  return await commonDataApi.get<PermissionListResponse>(url);
};

/**
 * Add permissions to group
 * @param {string} groupId
 * @param {string[]} permissions
 * @return {Promise<PermissionDocumentResponse>}
 */
const addGroupPermissions = async (
  groupId: string,
  permissions: string[]
): Promise<PermissionListResponse | PermissionDocumentResponse> => {
  const payload = permissions.map((permission) => ({
    permissionTypeId: permission
  }));
  const url = permissions.length && permissions.length > 1 ? "/batch" : "";
  return await commonDataApi.post(
    `groups/${groupId}/permissions${url}`,
    payload.length === 1 ? payload[0] : payload
  );
};

/**
 * Remove permissions from group
 * @param {string} groupId
 * @param {string[]} permissions
 * @return {Promise<PermissionDocumentResponse>}
 */
const removeGroupPermissions = async (
  groupId: string,
  permissions: string[]
): Promise<PermissionDocumentResponse> => {
  const payload = permissions.join(",");
  return await commonDataApi.delete(`groups/${groupId}/permissions?permissionTypeId=${payload}`);
};

/**
 * Add new group members to group
 * @param {string} groupId
 * @param {string[]} members
 * @return {Promise<PeopleListResponse>}
 */
const addGroupMembers = async (
  groupId: string,
  members: string[]
): Promise<PeopleDocumentResponse | PeopleListResponse> => {
  const payload = members.map((member) => ({ personId: member }));
  const url = members.length && members.length > 1 ? "/batch" : "";
  return await commonDataApi.post(
    `groups/${groupId}/members${url}`,
    payload.length === 1 ? payload[0] : payload
  );
};

/**
 * Remove members from group
 * @param {string} groupId
 * @param {string[]} members
 * @return {Promise<PeopleListResponse>}
 */
const removeGroupMembers = async (
  groupId: string,
  members: string[]
): Promise<PeopleListResponse> => {
  const payload = members.join(",");
  return await commonDataApi.delete(`groups/${groupId}/members?personId=${payload}`);
};

export const GroupsApi = {
  get: getGroups,
  getOne: getGroup,
  getByLocationId,
  getByUserId: getByUserId,
  create: createGroup,
  delete: deleteGroup,
  update: updateGroup,
  getGroupMembers,
  addGroupMembers,
  removeGroupMembers,
  getGroupPermissions,
  addGroupPermissions,
  removeGroupPermissions
};
