import { config } from "@nutrien-operations/config";

import { ApiInstance } from "../api-instance";

import { AlarmModesApi } from "./alarm-modes";
import { AssetNameplateDataApi } from "./asset-nameplate-data";
import { AssetNameplateTemplatesApi } from "./asset-nameplate-templates";
import { AssetPropertiesApi } from "./asset-properties";
import { AssetsApi } from "./assets";
import { AssetSubTypesApi } from "./asset-subtypes";
import { AssetTypesApi } from "./asset-types";
import { CsvUploadApi } from "./csv-upload";
import { DataSourceApi } from "./data-source";
import { NameplateLabelsApi } from "./nameplate-labels";
import { PersonasApi } from "./personas";
import { PlantsApi } from "./plants";
import { ProductionUnitsApi } from "./production-units";
import { SitesApi } from "./sites";
import { SubAreasApi } from "./sub-areas";
import { TagMappingApi } from "./tag-mapping";
import { TagNotificationsApi } from "./tag-notifications";
import { TagsApi } from "./tags";
import { TemplatesApi } from "./asset-templates";
import { VEmptyTemplateApi } from "./vempty-templates";
import { AssetPropertyNotesApi } from "./asset-property-notes";
import { AssetNotesApi } from "./asset-notes";
import { TagWeightsApi } from "./tag-weights";
import { SteadyStateApi } from "./steady-state";
import { AlarmThresholdApi } from "./alarm-threshold";
import { AssetSummaryApi } from "./asset-summary";
import { AlarmSummaryApi } from "./alarm-summary";

// Authenticated API connection wrapper for the Accelerator Api
export class AcceleratorApi {
  protected _instance: ApiInstance | null = null;

  constructor(prefixUrl: string) {
    this._instance = new ApiInstance(prefixUrl);
  }

  getInstance = () => this._instance;

  static AlarmModes = AlarmModesApi;
  static AssetNameplateData = AssetNameplateDataApi;
  static AssetNameplateTemplates = AssetNameplateTemplatesApi;
  static AssetNotes = AssetNotesApi;
  static AssetProperties = AssetPropertiesApi;
  static AssetPropertyNotesApi = AssetPropertyNotesApi;
  static Assets = AssetsApi;
  static AssetSubTypes = AssetSubTypesApi;
  static AssetTypes = AssetTypesApi;
  static CsvUpload = CsvUploadApi;
  static DataSource = DataSourceApi;
  static NameplateLabels = NameplateLabelsApi;
  static Personas = PersonasApi;
  static Plants = PlantsApi;
  static ProductionUnits = ProductionUnitsApi;
  static Sites = SitesApi;
  static SubAreas = SubAreasApi;
  static SteadyState = SteadyStateApi;
  static TagMappings = TagMappingApi;
  static TagNotifications = TagNotificationsApi;
  static Tags = TagsApi;
  static TagWeights = TagWeightsApi;
  static Templates = TemplatesApi;
  static VEmptyTemplate = VEmptyTemplateApi;
  static AlarmThreshold = AlarmThresholdApi;
  static AssetSummary = AssetSummaryApi;
  static AlarmSummary = AlarmSummaryApi;
}

export const legacyAcceleratorApi = new AcceleratorApi(
  config.API["legacy-accelerator"]
).getInstance();

export const acceleratorApi = new AcceleratorApi(config.API["accelerator"]).getInstance();
