import { materialBalanceApi } from ".";
import { AuditLogListResponse } from "../../types";
import {
  ManualReProcessQueueJobListResponse,
  ProductBalanceDocumentResponse,
  ProductBalanceListResponse,
  ProductBalanceNodeUpdate,
  ProductBalanceResultListResponse
} from "../../types/product-balance";

/**
 * Get product balance
 * @param productId {string}
 * @param productBalanceId {string}
 * @returns {NodeListResponse}
 */
export const getProductBalanceById = async (
  productId: string,
  productBalanceId: string
): Promise<ProductBalanceDocumentResponse> => {
  return await materialBalanceApi.get(`products/${productId}/balances/${productBalanceId}`);
};

/**
 * Get ProductBalance by date
 * @param productId {string}
 * @param date {string}
 * @returns {ProductBalanceListResponse}
 */
const getProductBalanceByDate = async (
  productId: string,
  date: string
): Promise<ProductBalanceDocumentResponse> => {
  const response = await materialBalanceApi.get<ProductBalanceListResponse>(
    `products/${productId}/balances?startdate=${date}&enddate=${date}`
  );

  // we only want to get a single product balance, but the endpoint always returns an array, so for convenience, we can map it to have a ProductBalanceDocumentResponse structure.
  if (response?.data[0]) {
    return { data: response.data[0] };
  }

  return undefined;
};

/**
 * Get ProductBalance by start date and end date
 * @param productId {string}
 * @param startDate {string}
 * @param endDate {string}
 * @returns {ProductBalanceListResponse}
 */
const getProductBalanceByDateRange = async (
  productId: string,
  startDate: string,
  endDate: string
): Promise<ProductBalanceListResponse> => {
  return await materialBalanceApi.get(
    `products/${productId}/balances?startdate=${startDate}&enddate=${endDate}`
  );
};

/**
 * Get the audit log of a product balance
 * @param productBalatnceId {string}
 * @param productId {string}
 * @param includeChildren {boolean}
 * @returns {AuditLogListResponse}
 */
export const getProductBalanceAuditLog = async (
  productBalanceId: string,
  productId: string,
  includeChildren?: boolean
): Promise<AuditLogListResponse> => {
  const products: AuditLogListResponse = await materialBalanceApi.get(
    `products/${productId}/balances/${productBalanceId}/auditLogs${
      includeChildren ? "?includeChildren=true" : ""
    }`
  );
  return products;
};

/**
 * Get the audit log of a product balance
 * @param productBalanceId {string}
 * @param productId {string}
 * @param nodeId {string}
 * @param includeChildren {boolean}
 * @returns {AuditLogListResponse}
 */
export const getProductBalanceAuditLogOfNode = async (
  productBalanceId: string,
  productId: string,
  reconcilerId: string,
  includeChildren?: boolean
): Promise<AuditLogListResponse> => {
  const products: AuditLogListResponse = await materialBalanceApi.get(
    `products/${productId}/balances/${productBalanceId}/nodes/${reconcilerId}/auditLogs${
      includeChildren ? "?includeChildren=true" : ""
    }`
  );
  return products;
};

/**
 * Update the nodes of a product balance
 * @param productId {string}
 * @param productBalanceId {string}
 * @param updates {ProductBalanceNodeUpdate[]}
 * @returns {ProductBalanceDocumentResponse}
 */
const updateProductBalanceNodes = async (
  productId: string,
  productBalanceId: string,
  updates: ProductBalanceNodeUpdate[]
): Promise<ProductBalanceDocumentResponse> => {
  return await materialBalanceApi.post(
    `products/${productId}/balances/${productBalanceId}/nodes`,
    updates
  );
};

/**
 * UnLock product balance
 * @param productId {string}
 * @param month {number}
 * @param year {number}
 * @return {Promise<void>}
 */
const unLockProductBalance = async (
  productId: string,
  month: number,
  year: number
): Promise<void> => {
  return await materialBalanceApi.post(
    `products/${productId}/unlock?month=${month}&year=${year}`,
    {}
  );
};

/**
 * Lock product balance
 * @param productId {string}
 * @param month {number}
 * @param year {number}
 * @return {Promise<void>}
 */
const lockProductBalance = async (
  productId: string,
  month: number,
  year: number
): Promise<void> => {
  return await materialBalanceApi.post(
    `products/${productId}/lock?month=${month}&year=${year}`,
    {}
  );
};

/**
 * Lock all product balance
 * @param siteId {string}
 * @param month {number}
 * @param year {number}
 * @return {Promise<void>}
 */
const lockAllProductBalance = async (
  siteId: string,
  month: number,
  year: number
): Promise<ProductBalanceResultListResponse> => {
  return await materialBalanceApi.post(
    `sites/${siteId}/balances/lock?month=${month}&year=${year}`,
    {}
  );
};

/**
 * Lock all product balance
 * @param siteId {string}
 * @param month {number}
 * @param year {number}
 * @return {Promise<void>}
 */
const unlockAllProductBalance = async (
  siteId: string,
  month: number,
  year: number
): Promise<ProductBalanceResultListResponse> => {
  return await materialBalanceApi.post(
    `sites/${siteId}/balances/unlock?month=${month}&year=${year}`,
    {}
  );
};

/**
 * Recalculate single product balance
 * @param recalculateProductBalance {string}
 * @return {Promise<void>}
 */
const recalculateProductBalance = async (
  productId: string,
  productBalanceId: string
): Promise<void> => {
  return await materialBalanceApi.get(
    `products/${productId}/balances/${productBalanceId}/recalculate`
  );
};

/**
 * Trigger manual reprocessing for a set of products during a particular range
 * @param productId {string}
 * @param month {number}
 * @param year {number}
 * @return {Promise<void>}
 */
const reprocessProductBalancesOfProducts = async (
  startDate: string,
  endDate: string,
  productIds: string[]
): Promise<void> => {
  return await materialBalanceApi.post("reprocess/invoke", {
    startDate,
    endDate,
    productIds
  });
};

/**
 * Get manual reprocess job queue for site
 * @param siteId {string}
 * @return {Promise<JobQueueListResponse>}
 */
const getReprocessJobQueueOfSite = async (
  siteId: string
): Promise<ManualReProcessQueueJobListResponse> => {
  return await materialBalanceApi.get(`reprocess/site/${siteId}/jobs`);
};

/**
 * Delete manual reprocess job queue for site
 * @param siteId {string}
 * @param jobId {string}
 * @return {Promise<void>}
 */
const deleteReprocessJobQueue = async (siteId: string, jobId: string): Promise<void> => {
  return await materialBalanceApi.delete(`reprocess/site/${siteId}/jobs/${jobId}`);
};

export const ProductBalancesApi = {
  Nodes: {
    update: updateProductBalanceNodes
  },
  unlockAll: unlockAllProductBalance,
  lockAll: lockAllProductBalance,
  unLock: unLockProductBalance,
  lock: lockProductBalance,
  getById: getProductBalanceById,
  getByDate: getProductBalanceByDate,
  getByDateRange: getProductBalanceByDateRange,
  getAuditLog: getProductBalanceAuditLog,
  getAuditLogOfNode: getProductBalanceAuditLogOfNode,
  getReprocessJobQueue: getReprocessJobQueueOfSite,
  manualReprocessBalancesOfProducts: reprocessProductBalancesOfProducts,
  recalculate: recalculateProductBalance,
  deleteReprocessJobQueue: deleteReprocessJobQueue
};
