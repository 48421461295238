import { QueryDslQueryContainer, SearchRequest } from "@elastic/elasticsearch/lib/api/types";
import { acceleratorApi } from ".";
import { AlarmSummary, AlarmSummaryListResponse } from "../../types/accelerator/alarm-summary";
import { escapeCharacters } from "../../utils";
import { SearchApiParams } from "../../types";

/**
 *  Get Virtual Tags
 * @param {string} searchTerm
 * @param {QueryOptions} [queryOptions={}]
 * @return {Promise<AlarmSummaryListResponse>}
 */
const searchAlarmSummary = async ({
  searchTerm,
  filter,
  sortModel,
  options
}: SearchApiParams<AlarmSummary>): Promise<AlarmSummaryListResponse> => {
  const from = options?.from || 0;
  const size = options?.size || 20;

  const searchBody: SearchRequest = {
    from,
    size,
    query: {
      bool: {
        should: [],
        must: [],
        must_not: [],
        filter: []
      }
    },
    sort: sortModel || {
      "name.keyword": { order: "asc", unmapped_type: "string" }
    }
  };

  if (searchTerm) {
    const isWildcardInput = !!searchTerm.match(/[*?]/);
    if (isWildcardInput) {
      (searchBody.query.bool.must as QueryDslQueryContainer[]).push({
        query_string: {
          query: `${escapeCharacters(searchTerm, true)}`,
          fields: ["*"]
        }
      });
    } else {
      (searchBody.query.bool.must as QueryDslQueryContainer[]).push({
        multi_match: {
          query: `${escapeCharacters(searchTerm, false)}`,
          type: "phrase_prefix",
          fields: ["*"]
        }
      });
    }
  }

  if (filter) {
    Object.entries(filter).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        (searchBody.query.bool.filter as QueryDslQueryContainer[]).push({
          terms: { [`${key}.keyword`]: value }
        });
      } else if (value || value === null) {
        (searchBody.query.bool.filter as QueryDslQueryContainer[]).push({
          term: { [`${key}.keyword`]: value }
        });
      }
    });
  }

  return await acceleratorApi.post("alarmSummaries/search", searchBody);
};

export const AlarmSummaryApi = {
  search: searchAlarmSummary
};
