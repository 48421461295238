import { QueryDslQueryContainer, SearchRequest } from "@elastic/elasticsearch/lib/api/types";
import { acceleratorApi } from ".";
import {
  AssetTemplateListResponse,
  Note,
  NoteListResponse,
  NoteResponse,
  TagListResponse,
  TagMapping,
  TagMappingListResponse,
  TagMappingResponse
} from "../../types/accelerator";
import { QueryOptions } from "../../types/api-definitions";
import { escapeCharacters } from "../../utils";
import { SearchFilter } from "../../types";
import { compare } from "fast-json-patch";

type TagMappingType = {
  assetTypeId: string;
  assetSubTypeId: string;
  assetId: string;
};

/**
 *  Get Tag mapping
 * @param {string} searchTerm
 * @param {QueryOptions} [queryOptions={}]
 * @return {Promise<AssetTemplateListResponse>}
 */
const searchTagMapping = async (
  searchTerm: string,
  filter: SearchFilter<TagMappingType>,
  options: QueryOptions = {}
): Promise<AssetTemplateListResponse> => {
  const from = options.from || 0;
  const size = options.size || 1000;

  const { assetId, ...restFilters } = filter;

  const searchBody: SearchRequest = {
    from,
    size,
    query: {
      bool: {
        should: [],
        must: [],
        must_not: [],
        filter: []
      }
    },
    script_fields: {
      script_output: {
        script: {
          lang: "painless",
          params: {
            assetId
          },
          source:
            "def tagMappings = []; def assetPropertyAnnotations = []; for (def assetPropertyAnnotation : params._source.assetPropertyAnnotations) { if (assetPropertyAnnotation.assetId == params['assetId']) { assetPropertyAnnotations.add(assetPropertyAnnotation); } } for (def tagMapping : params._source.tagMappings) { if (tagMapping.assetId == params['assetId']) { tagMappings.add(tagMapping); } } params._source.assetPropertyAnnotations = assetPropertyAnnotations; params._source.tagMappings = tagMappings; return params._source;"
        }
      }
    },
    sort: {
      "assetProperty.name.keyword": { order: "asc" }
    }
  };

  if (searchTerm) {
    const isWildcardInput = !!searchTerm.match(/[*?]/);
    if (isWildcardInput) {
      (searchBody.query.bool.must as QueryDslQueryContainer[]).push({
        query_string: {
          query: `${escapeCharacters(searchTerm, true)}`,
          fields: ["*"]
        }
      });
    } else {
      (searchBody.query.bool.must as QueryDslQueryContainer[]).push({
        multi_match: {
          query: `${escapeCharacters(searchTerm, false)}`,
          type: "phrase_prefix",
          fields: ["*"]
        }
      });
    }
  }

  if (restFilters) {
    Object.entries(restFilters).forEach(([key, value]) => {
      if (value || value === null) {
        (searchBody.query.bool.filter as QueryDslQueryContainer[]).push({
          term: { [`${key}.keyword`]: value }
        });
      }
    });
  }

  return await acceleratorApi.post("templates/search", searchBody);
};

/**
 * Create Tag Mappings
 *
 * @param tagMappings: @ TagMappings[]
 *
 * @return {Promise<TagMappingListResponse>}
 */
export const mapTags = async (tagMappings: TagMapping[]): Promise<TagMappingListResponse> => {
  return acceleratorApi.post("tagMappings/bulk", tagMappings);
};

/**
 * Add Notes
 *
 * @param notes[]: @ Note[]
 *
 * @return {Promise<void>}
 */
export const addNotes = async (notes: Note[]): Promise<NoteListResponse> => {
  return await acceleratorApi.post("assetPropertyAnnotations/bulk", notes);
};

/**
 * Update Note
 * @param note: @type Note
 *
 * @return {Promise<NoteResponse>}
 */
export const updateNotes = (note: Note): Promise<NoteResponse> => {
  return acceleratorApi.put(`assetPropertyAnnotations/${note.id}`, note);
};

/**
 * Patch Tag Mappings
 * @param previousValue: @type TagMapping
 * @param nextValue: @type Partial<TagMapping>
 *
 * @return {Promise<TagMappingResponse>}
 */
export const patchTagMapping = (
  previousValue: TagMapping,
  nextValue: Partial<TagMapping>
): Promise<TagMappingResponse> => {
  const patchUpdates = compare(previousValue, { ...previousValue, ...nextValue });
  return acceleratorApi.patch(`tagMappings/${previousValue.id}`, patchUpdates);
};

/**
 * Unmap Tag
 *
 * @param tagMappingId: @ String
 *
 * @return {Promise<void>}
 */
export const unmapTags = async (tagMappingId: string): Promise<void> => {
  return await acceleratorApi.delete(`tagMappings?id=${tagMappingId}`);
};

/**
 *  Get Tag mapping
 * @param {string} searchTerm
 * @param {QueryOptions} [queryOptions={}]
 * @return {Promise<TagListResponse>}
 */
const searchTungstenTags = async (
  tagName: string,
  system: string,
  query?: string,
  options: QueryOptions = {}
): Promise<TagListResponse> => {
  const from = options.from || 0;
  const size = options.size || 20;

  const searchBody: SearchRequest = {
    from,
    size,
    query: {
      bool: {
        must: [],
        filter: []
      }
    }
  };

  if (query) {
    searchBody.query = {
      query_string: {
        query
      }
    };
  } else {
    if (tagName) {
      searchBody.query.bool.must = {
        multi_match: {
          query: escapeCharacters(tagName),
          type: "phrase_prefix",
          fields: ["TagName"]
        }
      };
    }

    if (system) {
      (searchBody.query.bool.filter as QueryDslQueryContainer[]).push({
        term: { [`System.keyword`]: system }
      });
    }
  }

  searchBody.sort = {
    _score: { order: "desc" }
  };

  return await acceleratorApi.post("tungsten/tags/search", searchBody);
};

export const TagMappingApi = {
  search: searchTagMapping,
  update: patchTagMapping,
  mapTags,
  unmapTags,
  searchTungstenTags,
  addNotes,
  updateNotes
};
