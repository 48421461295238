import { useQuery } from "react-query";
import { ShiftLogsApprovalTypesListResponse } from "../../../types";
import { ShiftLogsApi } from "../../../apis";

export const useGetShiftLogApprovalTypes = () => {
  const { data, error, isLoading, refetch, isSuccess, isFetching } = useQuery<
    ShiftLogsApprovalTypesListResponse,
    Error
  >(["aproval-types"], () => ShiftLogsApi.LogsEntry.getApprovalTypes());

  return {
    approvalTypes: data?.data || [],
    error: error,
    loading: isLoading,
    isSuccess,
    isFetching,
    getApprovalsTypes: refetch
  };
};
