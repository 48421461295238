import { SearchRequest } from "@elastic/elasticsearch/lib/api/types";
import { acceleratorApi } from ".";
import { AssetSubType, AssetSubTypeDocumentResponse, AssetSubTypeListResponse } from "../../types";
import { urlPathWithQueryParams } from "../../utils";
import { Operation, compare } from "fast-json-patch";
/**
 * Get Asset Subtypes
 * @return {Promise<AssetSubTypeListResponse>}
 */
export const getAssetSubTypes = async (
  filter: Partial<AssetSubType> = {}
): Promise<AssetSubTypeListResponse> => {
  return acceleratorApi.get<AssetSubTypeListResponse>(
    urlPathWithQueryParams("assetSubTypes", { ...filter, page: 0, size: 1000 })
  );
};

/**
 * Get Asset Subtypes
 * @return {Promise<AssetSubTypeListResponse>}
 */
export const getAssetSubTypesByAssetTypeId = async (
  assetTypeId: string
): Promise<AssetSubTypeListResponse> => {
  const query: SearchRequest = {
    from: 0,
    size: 1000,
    query: {
      bool: {
        filter: [{ term: { ["assetTypeId.keyword"]: assetTypeId } }]
      }
    }
  };

  return acceleratorApi.post<SearchRequest, AssetSubTypeListResponse>(
    "assetSubTypes/search",
    query
  );
};

/**
 * Get Asset Sub Type By ID
 *
 * @param assetSubTypeId: @type String
 *
 * @return {Promise<AssetSubTypeDocumentResponse>}
 */
export const getAssetSubTypeById = async (
  assetSubTypeId: string
): Promise<AssetSubTypeDocumentResponse> => {
  return acceleratorApi.get<AssetSubTypeDocumentResponse>(`assetSubTypes/${assetSubTypeId}`);
};

/**
 * Create Asset Type
 *
 * @param assetSubType: @type Partial<AssetSubType>
 *
 * @return {Promise<AssetSubType>}
 */
export const createAssetSubType = async (
  assetSubType: Partial<AssetSubType>
): Promise<AssetSubTypeDocumentResponse> => {
  return acceleratorApi.post<Partial<AssetSubType>, AssetSubTypeDocumentResponse>(
    "assetSubTypes",
    assetSubType
  );
};

/**
 * Patch Asset Type
 *
 * @param assetSubTypeId: @type String
 * @param previousValue: @type AssetSubType
 * @param nextValue: @type Partial<AssetSubType>
 *
 * @return {Promise<AssetSubTypeDocumentResponse>}
 */
export const patchAssetSubType = async (
  assetSubTypeId: string,
  previousValue: AssetSubType,
  nextValue: Partial<AssetSubType>
): Promise<AssetSubTypeDocumentResponse> => {
  const patchUpdates = compare(previousValue, { ...previousValue, ...nextValue });
  return acceleratorApi.patch<Operation[], AssetSubTypeDocumentResponse>(
    `assetSubTypes/${assetSubTypeId}`,
    patchUpdates
  );
};

/**
 * Delete Asset Type
 *
 * @param assetSubTypeId: @type String
 *
 * @return {Promise<void>}
 */
export const deleteAssetSubType = async (assetSubTypeId: string): Promise<void> => {
  await acceleratorApi.delete(`assetSubTypes/${assetSubTypeId}`);
};

export const AssetSubTypesApi = {
  getAssetSubTypes,
  getByAssetId: getAssetSubTypesByAssetTypeId,
  getById: getAssetSubTypeById,
  patchAssetSubType,
  deleteAssetSubType,
  createAssetSubType
};
