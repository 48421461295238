import React from "react";

import {
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MUISelect,
  SelectProps
} from "@mui/material";
import { Control, Controller, Path } from "react-hook-form";
import { FieldValues } from "react-hook-form/dist/types/fields";

export type Option = { label: string; value: string | number; disabled?: boolean };

export type SelectElementProps<T> = Omit<SelectProps, "name"> & {
  control?: Control<T>;
  disabled?: boolean;
  empty?: boolean;
  emptyItemLabel?: string;
  helperText?: Path<T>;
  name: Path<T>;
  loading?: boolean;
  labelKey?: string;
  onOptionChange?: (data) => void;
  options?: T[] | Option[] | (string | number | boolean)[];
  sortOptions?: boolean;
  valueKey?: string;
};

const alphabeticalSorting = (a: Option, b: Option) => {
  return a.label > b.label ? 1 : b.label > a.label ? -1 : 0;
};

function Select<TFieldValues extends FieldValues>(
  props: SelectElementProps<TFieldValues>
): JSX.Element {
  const {
    control,
    disabled,
    empty,
    emptyItemLabel,
    helperText,
    loading,
    label,
    labelKey,
    name,
    onOptionChange,
    options,
    required,
    sortOptions,
    valueKey,
    variant,
    sx
  } = props;

  const composedOptions = React.useMemo(() => {
    const opts: Option[] = [];

    if (empty || emptyItemLabel) {
      opts.push({ label: emptyItemLabel || "-", value: "" });
    }

    options.forEach((option) => {
      if (["number", "string"].includes(typeof option)) {
        opts.push({
          label: option,
          value: option
        });
      } else if (labelKey && valueKey) {
        opts.push({
          label: option[labelKey],
          value: option[valueKey],
          disabled: option.disabled
        });
      }
    });

    return sortOptions ? opts.sort(alphabeticalSorting) : opts;
  }, [options]);

  return (
    <FormControl
      disabled={loading || disabled}
      className="mui-select"
      fullWidth
      variant={variant || "standard"}
      sx={{ my: 2, ...sx }}
    >
      <Controller
        render={({ field, fieldState: { error } }) => {
          const { onChange, ...rest } = field;
          const handleChange = (event, data) => {
            if (onOptionChange) {
              onOptionChange(data.props);
            }
            onChange(event, data);
          };
          return (
            <>
              <InputLabel
                error={!!error?.message}
                required={required}
                shrink={true}
                sx={{ fontWeight: 600, color: error?.message ? "#F24965" : "#333333" }}
              >
                {label}
                {loading && <CircularProgress sx={{ ml: 1 }} size={16} />}
              </InputLabel>

              <MUISelect {...rest} onChange={handleChange} label={label}>
                {emptyItemLabel && (
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                )}
                {composedOptions.map((option, index) => (
                  <MenuItem key={index} value={option.value} disabled={option.disabled}>
                    {option.label}
                  </MenuItem>
                ))}
              </MUISelect>

              <FormHelperText error={true}>{error?.message}</FormHelperText>
            </>
          );
        }}
        name={name}
        control={control}
      />

      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}
export default Select;
