import { isEmpty } from "@packages/utils";
import { compare, Operation } from "fast-json-patch";
import { Location } from "../types/location";
/**
 * Sort an array by key
 * @param {T[]} dataset
 * @param {string} field
 * @return {T[]}
 */
export const sortByKey = <T>(dataset: T[], field: string): T[] => {
  if (typeof dataset === "object" && Array.isArray(dataset) && dataset !== null) {
    return dataset.sort((a, b) => {
      if (a[field]) {
        return a[field].localeCompare(b[field]);
      } else {
        return a[field];
      }
    });
  }
  return dataset;
};

/**
 * @description
 * Takes an Array<V>, and a grouping function,
 * and returns a Map of the array grouped by the grouping function.
 *
 * @param list An array of type V.
 * @param keyGetter A Function that takes the the Array type V as an input, and returns a value of type K.
 *                  K is generally intended to be a property key of V.
 *
 * @returns Map of the array grouped by the grouping function.
 */
export function groupBy<K, V>(list: Array<V>, keyGetter: (input: V) => K): Map<K, Array<V>> {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

/**
 * Create url encoded path with query params provided as ain object
 * @param path - initial path of the url
 * @param obj - Any object of Record<string, string | number | boolean>
 * @returns url encoded string
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const urlPathWithQueryParams = (path, parameters: Record<string, any>): string => {
  if (isEmpty(parameters)) {
    return path;
  }

  const queryString = Object.entries(parameters)
    .filter(([_key, value]) => !isEmpty(value))
    .map(([key, value]) => encodeURIComponent(key) + "=" + encodeURIComponent(value))
    .join("&");

  return `${path}?${queryString}`;
};
/**
 * Escape Characters in ES search
 * @param {string} s
 * @param {boolean} wildCard
 * @return {string}
 */
export const escapeCharacters = (s: string, wildCard?: boolean) => {
  let result = "";
  for (let i = 0; i < s.length; i++) {
    const c = s.charAt(i);
    // These characters are part of the query syntax and must be escaped
    if (
      c === "\\" ||
      (c === "+" && !wildCard) ||
      (c === "-" && !wildCard) ||
      c === "!" ||
      c === "(" ||
      c === ")" ||
      c === ":" ||
      c === "^" ||
      c === "[" ||
      c === "]" ||
      c === '"' ||
      c === "{" ||
      c === "}" ||
      c === "~" ||
      (c === "*" && !wildCard) ||
      (c === "?" && !wildCard) ||
      c === "|" ||
      c === "&" ||
      c === "/"
    ) {
      result = result.concat("\\");
    } else if (c !== ">" && c !== "<") {
      // removed from string
      result = result.concat(c);
    }
  }
  return result.toString();
};

export const sortObjectKeys = <T>(obj) => {
  return Object.keys(obj)
    .sort()
    .reduce<T>(
      (acc, key) => ({
        ...acc,
        [key]: obj[key]
      }),
      {} as T
    );
};

/**
 * Sort an object who's values are objects by a key of the value object
 * @param {object} obj The object to have its keys sorted
 * @param {string} field The field from the value object to sort by
 * @returns {object} The inputted object with its keys in sorted order
 */
export const sortObjectByValueKey = <T>(obj: T, field: string): T => {
  return Object.entries(obj)
    .sort((a, b) => {
      if (a[1][field]) {
        if (typeof a[1][field] === "number") {
          return a[1][field] < b[1][field] ? -1 : 1;
        }

        return a[1][field].localeCompare(b[1][field]);
      } else {
        return a[1][field];
      }
    })
    .reduce<T>((sorted, entry) => {
      const [key, value] = entry;

      sorted[key] = value;
      return sorted;
    }, {} as T);
};

type LabelType = {
  selectedLocationTitle?: string;
  selectedAllLocationIds?: string[];
};

/**
 * Get selected location title with parent
 * @param {string} selectedLocationId
 * @param {Location[]} locations
 * @return {LabelType}
 */
export const getSelectedLocationLabel = (
  selectedLocationId: string,
  locations: Location[]
): LabelType => {
  if (!selectedLocationId) {
    return {};
  }

  const selectedLocationTitle: string[] = [];
  const selectedAllLocationIds: string[] = [];

  const getParent = (locationId: string) => {
    const location = locations.find((data) => data.locationId === locationId);
    if (location) {
      selectedLocationTitle.unshift(location.name);
      selectedAllLocationIds.unshift(location.locationId);
      getParent(location.parentLocationId);
    }
  };

  getParent(selectedLocationId);

  return {
    selectedLocationTitle: selectedLocationTitle.join(" > "),
    selectedAllLocationIds
  };
};

export type PatchJsonOperationsOptions = {
  replacePaths?: string[];
};

/**
 * A wrapper around `fast-json-patch` `compare` which completely replaces values at given `replacePaths`.
 * This is useful for replacing objects and array items, backend does not handle array items properly.
 *
 * @example
 *
 * // [{ op: 'replace', path: '/item', value: [1, 2, 3] }]
 * const ops = getPatchJsonOperations({ item: [1, 2] }, { item: [1, 2, 3] }, {
 *  replacePaths: ['item']
 * });
 */
export function getPatchJsonOperations(
  prev: object,
  next: object,
  opts: PatchJsonOperationsOptions
): Operation[] {
  // clone the objects to avoid mutating the inputs
  prev = structuredClone(prev);
  next = structuredClone(next);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const pathOpMap = new Map<string, { op: "add" | "replace"; value: any[] }>();

  // for all defined replacePaths, TODO remove paths on prev / next completely
  opts?.replacePaths?.forEach((path) => {
    const value = next[path];
    const patchPath = `/${path}`;

    // determine replace path previously existed or not
    if (path in prev) {
      pathOpMap.set(patchPath, { op: "replace", value });
    } else {
      pathOpMap.set(patchPath, { op: "add", value });
    }

    delete prev[path];
    delete next[path];
  });

  // generate the operations delta
  const operations = compare(prev, next);

  // add replace operations for the paths that were removed
  pathOpMap.forEach(({ op, value }, path) => {
    operations.push({ op, path, value });
  });

  return operations;
}
