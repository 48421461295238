import { Box } from "@mui/material";

interface CustomTabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

/**
 * @see https://mui.com/material-ui/react-tabs/#experimental-api
 * @deprecated use `TabPanel` with `TabContext` and `TabList` instead
 **/
export const CustomTabPanel = (props: CustomTabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ mt: 2 }}>{children}</Box>}
    </div>
  );
};
