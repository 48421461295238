import { QueryDslQueryContainer, SearchRequest } from "@elastic/elasticsearch/lib/api/types";
import { Operation, compare } from "fast-json-patch";
import { acceleratorApi } from ".";
import { Tag, TagListResponse, TagResponse } from "../../types/accelerator/tags";
import { escapeCharacters } from "../../utils";
import { SearchApiParams } from "../../types";

/**
 *  Get Tags
 * @param {string} searchTerm
 * @param {QueryOptions} [queryOptions={}]
 * @return {Promise<TagListResponse>}
 */
const searchTags = async ({
  searchTerm,
  filter,
  sortModel,
  options
}: SearchApiParams<Tag>): Promise<TagListResponse> => {
  const from = options?.from || 0;
  const size = options?.size || 20;

  const searchBody: SearchRequest = {
    from,
    size,
    query: {
      bool: {
        should: [],
        must: [],
        must_not: [],
        filter: []
      }
    },
    sort: sortModel || {
      "name.keyword": { order: "asc", unmapped_type: "string" }
    }
  };

  if (searchTerm) {
    const isWildcardInput = !!searchTerm.match(/[*?]/);
    if (isWildcardInput) {
      (searchBody.query.bool.must as QueryDslQueryContainer[]).push({
        query_string: {
          query: `${escapeCharacters(searchTerm, true)}`,
          fields: ["*"]
        }
      });
    } else {
      (searchBody.query.bool.must as QueryDslQueryContainer[]).push({
        multi_match: {
          query: `${escapeCharacters(searchTerm, false)}`,
          type: "phrase_prefix",
          fields: ["*"]
        }
      });
    }
  }

  if (filter) {
    Object.entries(filter).forEach(([key, value]) => {
      if (value || value === null) {
        (searchBody.query.bool.filter as QueryDslQueryContainer[]).push({
          terms: { [`${key}.keyword`]: value }
        });
      }
    });
  }

  return await acceleratorApi.post("tags/search", searchBody);
};

/**
 * Get Tag By ID
 *
 * @param tagId: @ String
 *
 * @return {Promise<TagResponse>}
 */
export const getTagById = async (tagId: string): Promise<TagResponse> => {
  return acceleratorApi.get<TagResponse>(`tags/${tagId}`);
};

/**
 * Search Tag By ID
 *
 * @param tagId: @ String
 *
 * @return {Promise<TagResponse>}
 */
export const searchTagById = async (tagId: string): Promise<TagListResponse> => {
  const searchBody: SearchRequest = {
    from: 0,
    size: 1,
    query: {
      bool: {
        filter: [
          {
            term: { [`id.keyword`]: tagId }
          }
        ]
      }
    }
  };
  return acceleratorApi.post("tags/search", searchBody);
};

/**
 * Create Tag
 *
 * @param tag: @type Partial<Tag>
 *
 * @return {Promise<Tag>}
 */
export const createTag = async (tag: Partial<Tag>): Promise<TagResponse> => {
  return acceleratorApi.post<Partial<Tag>, TagResponse>("tags", tag);
};

/**
 * Patch Tag
 * @param previousValue: @type Tag
 * @param nextValue: @type Partial<Tag>
 *
 * @return {Promise<TagResponse>}
 */
export const patchTag = async (previousValue: Tag, nextValue: Partial<Tag>) => {
  const patchUpdates = compare(previousValue, { ...previousValue, ...nextValue });
  return acceleratorApi.patch<Operation[], TagResponse>(`tags/${previousValue.id}`, patchUpdates);
};

/**
 * Delete Tag
 *
 * @param tagId: @ String
 *
 * @return {Promise<void>}
 */
export const deleteTag = async (tagId: string): Promise<void> => {
  await acceleratorApi.delete(`tags/${tagId}`);
};

export const TagsApi = {
  search: searchTags,
  getById: getTagById,
  searchById: searchTagById,
  create: createTag,
  update: patchTag,
  delete: deleteTag
};
