import { QueryDslQueryContainer, SearchRequest } from "@elastic/elasticsearch/lib/api/types";
import { Operation, compare } from "fast-json-patch";
import { acceleratorApi } from ".";
import { Asset, AssetListResponse, AssetResponse } from "../../types/accelerator/assets";
import { escapeCharacters } from "../../utils";
import {
  AssetAbnormalThresholds,
  AssetAbnormalThresholdsResponse,
  SearchApiParams
} from "../../types";

/**
 *  Get Asset
 * @param {string} searchTerm
 * @param {QueryOptions} [queryOptions={}]
 * @return {Promise<AssetListResponse>}
 */
const searchAssets = async ({
  searchTerm,
  filter,
  otherFields,
  sortModel,
  options
}: SearchApiParams<Asset>): Promise<AssetListResponse> => {
  const from = options?.from || 0;
  const size = options?.size || 20;

  const searchBody: SearchRequest = {
    from,
    size,
    query: {
      bool: {
        should: [],
        must: [],
        filter: []
      }
    },
    sort: sortModel || {
      "assetCode.keyword": { order: "asc", unmapped_type: "string" }
    }
  };

  if (searchTerm) {
    const isWildcardInput = !!searchTerm.match(/[*?]/);
    if (isWildcardInput) {
      (searchBody.query.bool.must as QueryDslQueryContainer[]).push({
        query_string: {
          query: `${escapeCharacters(searchTerm, true)}`,
          fields: ["assetCode", "description"]
        }
      });
    } else {
      (searchBody.query.bool.must as QueryDslQueryContainer[]).push({
        multi_match: {
          query: `${escapeCharacters(searchTerm, false)}`,
          type: "phrase_prefix",
          fields: ["assetCode", "description"]
        }
      });
    }
  }

  if (otherFields) {
    const notAllowed = otherFields as string[];
    notAllowed?.forEach((key) => {
      (searchBody.query.bool.must as QueryDslQueryContainer[]).push({
        exists: {
          field: key
        }
      });
    });
  }

  if (filter) {
    Object.entries(filter).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        (searchBody.query.bool.filter as QueryDslQueryContainer[]).push({
          terms: { [`${key}.keyword`]: value }
        });
      } else if (value || value === null) {
        (searchBody.query.bool.filter as QueryDslQueryContainer[]).push({
          term: { [`${key}.keyword`]: value }
        });
      }
    });
  }

  const response = await acceleratorApi.post<typeof searchBody, AssetListResponse>(
    "assets/search",
    searchBody
  );

  // append `sourceAssets` to `relatedAssets`
  response.data.forEach((asset) => {
    asset.relatedAssets = [...asset.relatedAssets, ...asset.sourceAssets];
  });

  return response;
};

/**
 *  Get Asset
 * @param {string} searchTerm
 * @param {QueryOptions} [queryOptions={}]
 * @return {Promise<AssetListResponse>}
 */
const searchAssetsData = async ({
  searchTerm,
  filter,
  otherFields,
  sortModel,
  options
}: SearchApiParams<Asset>): Promise<AssetListResponse> => {
  const from = options?.from || 0;
  const size = options?.size || 20;

  const searchBody: SearchRequest = {
    from,
    size,
    query: {
      bool: {
        should: [],
        must: [],
        filter: []
      }
    },
    sort: sortModel || {
      "assetCode.keyword": { order: "asc", unmapped_type: "string" }
    }
  };

  if (searchTerm) {
    const isWildcardInput = !!searchTerm.match(/[*?]/);
    if (isWildcardInput) {
      (searchBody.query.bool.must as QueryDslQueryContainer[]).push({
        query_string: {
          query: `${escapeCharacters(searchTerm, true)}`,
          fields: ["*"]
        }
      });
    } else {
      (searchBody.query.bool.must as QueryDslQueryContainer[]).push({
        multi_match: {
          query: `${escapeCharacters(searchTerm)}`,
          type: "phrase_prefix",
          fields: ["*"]
        }
      });
    }
  }

  if (otherFields) {
    const notAllowed = otherFields as string[];
    notAllowed?.forEach((key) => {
      (searchBody.query.bool.must as QueryDslQueryContainer[]).push({
        exists: {
          field: key
        }
      });
    });
  }

  if (filter) {
    Object.entries(filter).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        (searchBody.query.bool.filter as QueryDslQueryContainer[]).push({
          terms: { [`${key}.keyword`]: value }
        });
      } else if (value || value === null) {
        (searchBody.query.bool.filter as QueryDslQueryContainer[]).push({
          term: { [`${key}.keyword`]: value }
        });
      }
    });
  }

  const response = await acceleratorApi.post<typeof searchBody, AssetListResponse>(
    "assets/search",
    searchBody
  );

  // append `sourceAssets` to `relatedAssets`
  response.data.forEach((asset) => {
    asset.relatedAssets = [...asset.relatedAssets, ...asset.sourceAssets];
  });

  return response;
};

/**
 * Search asset By ID
 *
 * @param assetId: @ String
 *
 * @return {Promise<TagWeightsListResponse>}
 */
export const searchAssetById = async (assetId: string): Promise<AssetListResponse> => {
  const searchBody: SearchRequest = {
    from: 0,
    size: 1,
    query: {
      bool: {
        filter: [
          {
            term: { [`id.keyword`]: assetId }
          }
        ]
      }
    }
  };
  return acceleratorApi.post("assets/search", searchBody);
};

/**
 * Get Asset  By ID
 *
 * @param assetId: @ String
 *
 * @return {Promise<AssetResponse>}
 */
export const getAssetById = async (assetId: string): Promise<AssetResponse> => {
  return acceleratorApi.get<AssetResponse>(`assets/${assetId}`);
};

/**
 * Create Asset
 *
 * @param asset: @ Partial<Asset>
 *
 * @return {Promise<Asset>}
 */
export const createAsset = async (asset: Partial<Asset>): Promise<AssetResponse> => {
  return acceleratorApi.post<Partial<Asset>, AssetResponse>("assets", asset);
};

/**
 * Patch Asset
 * @param previousValue: @type AssetType
 * @param nextValue: @type Partial<AssetType>
 *
 * @return {Promise<AssetResponse>}
 */
export const patchAsset = async (previousValue: Asset, nextValue: Partial<Asset>) => {
  const { relatedAssetIds, ...rest } = previousValue;
  const { relatedAssetIds: nextRelatedAssetIds, ...restNextValue } = nextValue;

  const patchUpdates = compare(rest, { ...rest, ...restNextValue });
  if (relatedAssetIds.sort().join(",") !== nextRelatedAssetIds.sort().join(",")) {
    patchUpdates.push({
      op: "replace",
      path: "/relatedAssetIds",
      value: nextRelatedAssetIds
    });
  }
  return acceleratorApi.patch<Operation[], AssetResponse>(
    `assets/${previousValue.id}`,
    patchUpdates
  );
};

/**
 * Get Assets
 * @return {Promise<AssetListResponse>}
 */
export const getAssets = async (): Promise<AssetListResponse> => {
  return acceleratorApi.get("assets");
};

/**
 * Delete Asset
 *
 * @param assetId: @ String
 *
 * @return {Promise<void>}
 */
export const deleteAsset = async (assetId: string): Promise<void> => {
  await acceleratorApi.delete(`assets/${assetId}`);
};

/**
 *  Get AssetAbnormalThresholds
 * @param {string} searchTerm
 * @param {QueryOptions} [queryOptions={}]
 * @return {Promise<AssetListResponse>}
 */
const searchAssetAbnormalThresholds = async ({
  searchTerm,
  filter,
  otherFields,
  sortModel,
  options
}: SearchApiParams<Asset>): Promise<AssetListResponse> => {
  const from = options?.from || 0;
  const size = options?.size || 20;

  const searchBody: SearchRequest = {
    from,
    size,
    query: {
      bool: {
        should: [],
        must: [],
        filter: []
      }
    },
    sort: sortModel || {
      "assetCode.keyword": { order: "asc", unmapped_type: "string" }
    }
  };

  if (searchTerm) {
    (searchBody.query.bool.must as QueryDslQueryContainer[]).push({
      multi_match: {
        query: escapeCharacters(searchTerm),
        type: "phrase_prefix",
        fields: ["*"]
      }
    });
  }

  if (otherFields) {
    const notAllowed = otherFields as string[];
    notAllowed?.forEach((key) => {
      (searchBody.query.bool.must as QueryDslQueryContainer[]).push({
        exists: {
          field: key
        }
      });
    });
  }

  if (filter) {
    Object.entries(filter).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        (searchBody.query.bool.filter as QueryDslQueryContainer[]).push({
          terms: { [`${key}.keyword`]: value }
        });
      } else if (value || value === null) {
        (searchBody.query.bool.filter as QueryDslQueryContainer[]).push({
          term: { [`${key}.keyword`]: value }
        });
      }
    });
  }
  return await acceleratorApi.post("assetAbnormalThresholds/search", searchBody);
};

/**
 * Patch AssetAbnormalThresholds
 * @param previousValue: @type AssetAbnormalThresholds
 * @param nextValue: @type Partial<AssetAbnormalThresholds>
 *
 * @return {Promise<AssetResponse>}
 */
export const patchAssetAbnormalThresholds = async (
  previousValue: AssetAbnormalThresholds,
  nextValue: Partial<AssetAbnormalThresholds>
) => {
  const patchUpdates = compare(previousValue, { ...previousValue, ...nextValue });
  return acceleratorApi.patch<Operation[], AssetAbnormalThresholdsResponse>(
    `assetAbnormalThresholds/${previousValue.id}`,
    patchUpdates
  );
};

export const AssetsApi = {
  searchAssets,
  search: searchAssetsData,
  searchAssetAbnormalThresholds,
  searchAssetById,
  createAsset,
  getAssets,
  deleteAsset,
  getAssetById,
  updateAsset: patchAsset,
  updateAssetAbnormalThresholds: patchAssetAbnormalThresholds
};
