import { UseQueryOptions, useQuery } from "react-query";
import { HTTPError, LocationListResponse } from "../../../types";
import { CommonDataApi } from "../../..";
import { locationsOfSite } from "./query-cache";

/**
 * Hook to get a list of locations for a site.
 */
export const useGetLocations = (
  applicationId: string,
  siteId: string,
  includeApplicationRoot = false
) => {
  const queryOptions: UseQueryOptions<LocationListResponse, HTTPError> = {
    queryKey: locationsOfSite(applicationId, siteId),
    queryFn: () =>
      CommonDataApi.Locations.get(
        applicationId,
        siteId,
        {},
        { size: 1000, includeApplicationRoot }
      ),
    enabled: !!siteId && !!applicationId,
    select: (data) => {
      data?.data?.sort((a, b) =>
        a.name?.toLocaleLowerCase().localeCompare(b.name?.toLocaleLowerCase())
      );
      return data;
    }
  };
  const { data, isLoading, isError, isSuccess } = useQuery(queryOptions);

  return {
    locations: data ? data.data : [],
    loading: isLoading,
    isError,
    isSuccess
  };
};
