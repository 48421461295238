import { Box, Typography, styled } from "@mui/material";
import { useId } from "react";

export type LabelValueProps = {
  label: React.ReactNode;
  value: React.ReactNode;
};

export function LabelValue({ label, value }: LabelValueProps) {
  const id = useId();
  const idDesc = `${id}-desc`;
  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start">
      <Typography variant="caption" id={id} aria-describedby={idDesc} color="text.secondary">
        {label}
      </Typography>
      <Typography variant="body2" id={idDesc}>
        {value}
      </Typography>
    </Box>
  );
}

export const LabelValueGrid: typeof Box = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  align-items: baseline;
  gap: 15px;
` as unknown as typeof Box;
