import { compare } from "fast-json-patch";

import { shiftLogsApi } from ".";
import {
  ShiftLogs,
  ShiftLogsApproval,
  ShiftLogsApprovalTypesListResponse,
  ShiftLogsApprovalsDocumentResponse,
  ShiftLogsApprovalsListResponse,
  ShiftLogsDocumentResponse,
  ShiftLogsListResponse
} from "../../types";
import { urlPathWithQueryParams } from "../../utils";

/**
 * Create a new log entry
 * @param shiftLogs {ShiftLogs[]}
 * @returns {ShiftLogsListResponse}
 */
export const createShiftLogEntries = async (
  shiftLogs: ShiftLogs[]
): Promise<ShiftLogsListResponse> => {
  return await shiftLogsApi.post("logs", shiftLogs);
};

/**
 * Update log text
 * @param shiftLogId {string}
 * @param logText {string}
 * @returns {ShiftLogsDocumentResponse}
 */
export const updateShiftLogEntryText = async (
  shiftLogId: string,
  logText: string
): Promise<ShiftLogsDocumentResponse> => {
  return await shiftLogsApi.post(`logs/${shiftLogId}/logText`, { logText });
};

/**
 * Get log by shiftLogId
 * @param shiftLogId {string}
 * @returns {ShiftLogsDocumentResponse}
 */
export const getShiftLogEntryById = async (
  shiftLogId: string
): Promise<ShiftLogsDocumentResponse> => {
  return await shiftLogsApi.get(`logs/${shiftLogId}`);
};

/**
 * Get logs
 * @param locationId {string}
 * @param shiftId {string}
 * @param date {string}
 * @returns {ShiftLogsDocumentResponse}
 */
export const getShiftLogEntries = async (
  locationId: string,
  shiftId: string,
  date: string
): Promise<ShiftLogsListResponse> => {
  return await shiftLogsApi.get(urlPathWithQueryParams(`logs`, { locationId, shiftId, date }));
};

/**
 * Get approvals list
 * @param locationId {string}
 * @param shiftId {string}
 * @param date {string}
 * @returns {ShiftLogsApprovalsListResponse}
 */
export const getShiftLogApprovals = async (
  locationId: string,
  shiftId: string,
  date: string
): Promise<ShiftLogsApprovalsListResponse> => {
  return await shiftLogsApi.get(
    urlPathWithQueryParams("logs/approvals", { locationId, shiftId, date })
  );
};

/**
 * Approve shift log
 * @param approval {ShiftLogsApproval}
 * @returns {ShiftLogsApprovalsDocumentResponse}
 */
export const shiftLogApprovals = async (
  approval: ShiftLogsApproval
): Promise<ShiftLogsApprovalsDocumentResponse> => {
  return await shiftLogsApi.post("logs/approvals", approval);
};

/**
 * Approve shift log
 * @param approval {ShiftLogsApproval}
 * @returns {ShiftLogsApprovalsDocumentResponse}
 */
export const shiftLogApprovalTypes = async (): Promise<ShiftLogsApprovalTypesListResponse> => {
  return await shiftLogsApi.get("logs/approvalTypes");
};

/**
 * Update log entry
 * @param shiftLogId {string}
 * @param logText {string}
 * @returns {ShiftLogsDocumentResponse}
 */
export const updateShiftLogEntry = async (
  previousLogEntry: Partial<ShiftLogs>,
  logEntry: Partial<ShiftLogs>
): Promise<ShiftLogsDocumentResponse> => {
  const patchUpdates = compare(previousLogEntry, logEntry);
  return await shiftLogsApi.patch(`logs/${previousLogEntry.shiftLogId}`, patchUpdates);
};

export const LogsEntryApi = {
  get: getShiftLogEntries,
  getById: getShiftLogEntryById,
  create: createShiftLogEntries,
  update: updateShiftLogEntry,
  updateShiftLogEntryText,
  getApprovals: getShiftLogApprovals,
  getApprovalTypes: shiftLogApprovalTypes,
  approve: shiftLogApprovals
};
