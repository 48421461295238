import { FC, isValidElement, ReactElement, useCallback, useState } from "react";
import { ExcelExportParams, CsvExportParams, GridReadyEvent } from "ag-grid-community";
import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip
} from "@mui/material";
import { ApplicationIcon } from "@packages/theme-mui-v5";

export type AgGridToolbarProps = {
  grid: GridReadyEvent;
  // Enable CSV export
  enableCsvExport?: boolean;
  // Enable quick search
  enableQuickSearch?: boolean;
  // Optional toolbar component
  toolbarRightComponent?: ReactElement;
  toolbarLeftComponent?: ReactElement;
  // Multi cell editing
  multiCellEditingRenderer?: ReactElement;
  isServersidePagination: boolean;
  csvExportFileName?: string;
  onQuickFilterChange?: (searchTerm: string) => void;
  excelExportFileName?: string;
  enableExcelExport?: boolean;
  excelExportParams?: ExcelExportParams;
  exportDataAsCsv?: (grid: GridReadyEvent, csvExportParams: CsvExportParams) => void;
  exportDataAsExcel?: (grid: GridReadyEvent, excelExportParams: ExcelExportParams) => void;
};

export const AgGridToolbar: FC<AgGridToolbarProps> = ({
  grid,
  enableCsvExport,
  exportDataAsCsv,
  exportDataAsExcel,
  enableQuickSearch,
  toolbarRightComponent,
  toolbarLeftComponent,
  multiCellEditingRenderer,
  isServersidePagination,
  csvExportFileName,
  excelExportFileName,
  enableExcelExport,
  excelExportParams,
  onQuickFilterChange
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [exportLoading, setExportLoading] = useState<boolean>(false);

  const handleCsvExport = useCallback(async () => {
    const csvExportParams: CsvExportParams = {
      fileName: `${csvExportFileName}.csv`
    };
    if (exportDataAsCsv) {
      setExportLoading(true);
      await exportDataAsCsv(grid, csvExportParams);
      setExportLoading(false);
    } else {
      grid?.api?.exportDataAsCsv(csvExportParams);
    }
  }, [grid, csvExportFileName, exportDataAsCsv]);

  const handleExcelExport = useCallback(async () => {
    const excelParams: ExcelExportParams = {
      fileName: `${excelExportFileName}.xlsx`,
      sheetName: excelExportFileName,
      ...excelExportParams
    };

    if (exportDataAsExcel) {
      setExportLoading(true);
      await exportDataAsExcel(grid, excelParams);
      setExportLoading(false);
    } else {
      grid?.api?.exportDataAsExcel(excelParams);
    }
  }, [grid, excelExportFileName, exportDataAsExcel, isServersidePagination]);

  const onQuickSearchTextChange = useCallback(
    async (event) => {
      setSearchTerm(event.target.value);
      if (isServersidePagination) {
        if (onQuickFilterChange) {
          onQuickFilterChange(event.target.value);
        }
      } else {
        grid?.api.updateGridOptions({ quickFilterText: event.target.value });
      }
    },
    [grid, isServersidePagination]
  );

  const handleResetFilter = useCallback(() => {
    grid.api.setFilterModel(null);
    setSearchTerm("");
    if (isServersidePagination) {
      if (onQuickFilterChange) {
        onQuickFilterChange("");
      }
    } else {
      grid?.api.updateGridOptions({ quickFilterText: "" });
    }
  }, [grid, isServersidePagination]);

  const showToolbarRightComponent = toolbarRightComponent && isValidElement(toolbarRightComponent);

  const showToolbarLeftComponent = toolbarLeftComponent && isValidElement(toolbarLeftComponent);

  const showMultiCellEditingRenderer = isValidElement(multiCellEditingRenderer);

  const showAgGridToolbar =
    enableCsvExport ||
    enableExcelExport ||
    enableQuickSearch ||
    showMultiCellEditingRenderer ||
    toolbarLeftComponent ||
    showToolbarRightComponent;

  if (!showAgGridToolbar) {
    return null;
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 1,
        px: 1,
        py: 2,
        border: 1,
        borderBottom: 0,
        borderColor: "grey.A200"
      }}
    >
      <Box>
        {showToolbarLeftComponent && (
          <Box className="toolbarLeftComponent">{toolbarLeftComponent}</Box>
        )}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: 1 }}>
        {showToolbarRightComponent && <Box>{toolbarRightComponent}</Box>}

        {showMultiCellEditingRenderer && <Box>{multiCellEditingRenderer}</Box>}

        {(enableCsvExport || exportDataAsCsv) && (
          <Box
            sx={{
              color: "primary.main"
            }}
          >
            <Tooltip title="Export to CSV">
              <IconButton
                disabled={exportLoading}
                color="primary"
                size="small"
                onClick={handleCsvExport}
              >
                {exportLoading ? (
                  <CircularProgress size={14} />
                ) : (
                  <ApplicationIcon name="download" />
                )}
              </IconButton>
            </Tooltip>
          </Box>
        )}

        {(enableExcelExport || exportDataAsExcel) && (
          <Box sx={{ color: "primary.main" }}>
            <Tooltip title="Export to Excel">
              <IconButton
                disabled={exportLoading}
                color="primary"
                size="small"
                onClick={handleExcelExport}
              >
                {exportLoading ? (
                  <CircularProgress size={14} />
                ) : (
                  <ApplicationIcon name="download" />
                )}
              </IconButton>
            </Tooltip>
          </Box>
        )}

        {enableQuickSearch && (
          <Box
            sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: 1 }}
          >
            <Box sx={{ color: "primary.main" }}>
              <Tooltip title="Reset Filters">
                <IconButton color="primary" size="small" onClick={handleResetFilter}>
                  <ApplicationIcon name="reset" />
                </IconButton>
              </Tooltip>
            </Box>
            <Box>
              <TextField
                size="small"
                variant="standard"
                onInput={onQuickSearchTextChange}
                value={searchTerm}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Box component="span" sx={{ padding: 1, color: "primary.main" }}>
                        <ApplicationIcon name="search" />
                      </Box>
                    </InputAdornment>
                  )
                }}
                sx={{ backgroundColor: "white" }}
                inputProps={{
                  placeholder: "Quick search"
                }}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
